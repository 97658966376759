<template>
    <div>
        <button class = 'btn btn-secondary-info btn-micro resposta' @click.prevent="">
            N/A
        </button>
    </div>
</template>
<script>
export default {
  name: 'Na',
};
</script>
