<template>
  <div class="form-elements">
    <div class="va-row">
      <div class="flex md12">
        <vuestic-widget>
          <div class="pergunta">
            <div class="va-row">
              <div class="flex md12">
                <fieldset>
                  <div class="form-group">
                    <div class="input-group">
                      <p>Pergunta:</p>
                      <input
                        v-model="pergunta.pergunta"
                        id="simple-input"
                        required
                      >
                      <label
                        class="control-label"
                        for="simple-input"
                      ></label>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
          <div>
            <div>Tipos de Resposta</div>
            <br>
            <div class="va-row">
              <div class="flex md12">
                <div class="alinh">
                  <ul>
                    <li class="list">
                      <input
                        type="radio"
                        id="tres"
                        value="1"
                        v-model="pergunta.tipos_de_respostas"
                      >
                      <todas :tipo="1"></todas>

                    </li>
                    <li class="list">
                      <input
                        type="radio"
                        id="tres"
                        value="2"
                        v-model="pergunta.tipos_de_respostas"
                      >
                      <todas :tipo="2"></todas>
                      <br>
                    </li>
                    <li class="list">
                      <input
                        type="radio"
                        id="tres"
                        value="3"
                        v-model="pergunta.tipos_de_respostas"
                      >
                      <todas :tipo="3"></todas>
                      <br>
                    </li>
                  </ul>
                  <ul>
                    <li class="list">
                      <input
                        type="checkbox"
                        id="tres"
                        v-model="pergunta.nao_se_aplica"
                      >
                      <na></na>
                    </li>
                  </ul>
                </div>

              </div>
            </div>
          </div>
          <div class="butoes">
            <button
              class="btn btn-pale btn-micro"
              @click="$router.push({
                name: 'cadastrar-checklist-id',
                params: {checklist: idChecklist}})"
            >Voltar</button>
            <button
              class="btn btn-info btn-micro"
              @click.stop.prevent="editarPergunta()"
            >Salvar</button>
          </div>
        </vuestic-widget>
      </div>
    </div>
    <vuestic-modal
      :show.sync="show"
      v-bind:small="true"
      v-bind:force="true"
      ref="staticModal"
      :cancelClass="'none'"
      :okText="'Confirmar' | translate"
    >
      <div slot="title">{{'Pergunta Cadastrada' | translate}}</div>
      <div>
        {{'Pergunta cadastrada com sucesso' | translate}}
      </div>
    </vuestic-modal>
  </div>
</template>

<script>
import todas from '../../../../vuestic-theme/vuestic-components/vuestic-tipo_de_resposta/todasAsRespostas.vue';
import Na from '../../../../vuestic-theme/vuestic-components/vuestic-tipo_de_resposta/Na.vue';

export default {
  name: 'editar-pergunta',
  components: {
    todas,
    Na,
  },
  data() {
    return {
      show: true,
      idPergunta: null,
      idChecklist: null,
      pergunta: {},
      areaChecklist: [],
    };
  },
  created() {
    this.idPergunta = this.$router.history.current.params.id;
    this.idChecklist = this.$router.history.current.params.checklist;
    this.getPergunta();
  },
  methods: {
    showStaticModal() {
      this.$refs.staticModal.open();
    },

    editarPergunta() {
      this.$axios.put(`/api/pergunta/${this.idPergunta}/`, this.pergunta).then(() => {
        // eslint-disable-next-line no-alert
        alert('Pergunta alterada com sucesso!');
      });
    },

    getPergunta() {
      this.$axios.get(`/api/pergunta/${this.idPergunta}/`).then((res) => {
        this.pergunta = res.data;
      });
    },
  },
};
</script>

<style lang="scss">
.adicionar {
  display: flex;
  align-items: center;
}
.adicionar input {
  flex-basis: 60%;
}
.margin_entre_icones {
  margin: 3.5%;
}
.icons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.icons .fa {
  margin: 1%;
}
.pergunta {
  flex-direction: column;
}
.resposta {
  display: flex;
  align-items: center;
  margin: 1%;
}
.butoes {
  display: flex;
  justify-content: flex-end;
}
.resposta #duas duas {
  width: 100px;
}
.butoes .btn-pale {
  margin-right: 0.8%;
}
.list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 6%;
  width: 450px;
}

#tres {
  margin-right: 1%;
}
.alinh {
  display: flex;
  justify-content: space-between;
}
</style>
